import axios from "@/axios.js"

export default {
  addItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/cms/group", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/group")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/group/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/cms/group/${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
