import state from './moduleGroupState';
import mutations from './moduleGroupMutations';
import actions from './moduleGroupActions';
import getters from './moduleGroupGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
